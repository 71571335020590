import { Quest } from "../../models/api/quest";
import { useCallback } from "react";
import { json } from "react-router";
import { AxiosError } from "axios";
import { getFolders } from "../../api/quests/questFolders";

const useQuestsLoader = (getQuests: () => Promise<Quest[]>) => {
  const loader = useCallback(async () => {
    try {
      const questFolders = await getFolders();
      const quests = await getQuests();

      return {
        questFolders,
        quests,
      };
    } catch (error) {
      console.error(error);

      if (error instanceof AxiosError) {
        throw json(
          {
            message: error.response?.data.message,
            details: error.response?.data.details,
          },
          {
            status: error.response?.data.message === "Unauthorized" ? 401 : error.status,
          }
        );
      }

      throw json(
        {
          message: "Error occurred fetching quests...",
        },
        {
          status: 500,
        }
      );
    }
  }, []);

  return { loader };
};

export default useQuestsLoader;
