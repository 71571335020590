import { useCallback } from "react";
import { getCachedQuests } from "../../db";
import { useStoreDispatch } from "../../store/hooks";
import { setQuests } from "../../store/quests";

const useQuestsOverviewLoader = () => {
  const dispatch = useStoreDispatch();

  const loader = useCallback(async () => {
    try {
      return {
        nodes: [],
        edges: [],
      };
    } catch (error) {
      console.error(error);

      return {
        nodes: [],
        edges: [],
      };
    }
  }, [dispatch]);

  return { loader };
};

export default useQuestsOverviewLoader;
