import { ChakraProps, Heading, Stack, Tag, Text, Wrap, WrapItem } from "@chakra-ui/react";
import { AllegianceWithAmount } from "@worldwidewebb/quest-shared/dist/allegiance";
import { ItemNameAndQuantity } from "@worldwidewebb/quest-shared/dist/item";
import { SkillWithLevel } from "@worldwidewebb/quest-shared/dist/skill";
import { memo, ReactElement, useMemo } from "react";
import { Node } from "reactflow";
import { QuestWithId } from "../../models/api/quest";
import { NodeType } from "../../models/nodeType";
import { StartConditionType, StartNodeData } from "../../store/quests";
import AllegianceWithAmountComponent from "../allegiances/AllegianceWithAmountComponent";
import ItemNameAndQuantityComponent from "../items/ItemNameAndQuantityComponent";
import SkillWithLevelComponent from "../skills/SkillWithLevelComponent";
import QuestName from "./QuestName";

interface QuestStartConditionsByTypeProps<T> extends ChakraProps {
  label: string;
  items: T[];
  render: (item: T) => ReactElement;
}

function QuestStartConditionsByType<T>({ color, label, items, render }: QuestStartConditionsByTypeProps<T>) {
  if (items.length === 0) {
    return null;
  }

  return (
    <>
      <Heading size={"sm"}>
        <Text color={color} casing={"uppercase"}>
          {label}
        </Text>
      </Heading>

      <Wrap>
        {items.map((item, index) => (
          <WrapItem key={index}>{render(item)}</WrapItem>
        ))}
      </Wrap>
    </>
  );
}

interface QuestStartConditionsProps extends ChakraProps {
  quest: QuestWithId;
}

function QuestStartConditions({ color, quest }: QuestStartConditionsProps) {
  const { data: questData } = quest;

  if (questData == null) {
    return null;
  }

  const startNodes: Node<NodeType<StartNodeData>>[] = questData.nodes.filter(
    ({ data: { nodeClass } }) => nodeClass === "start"
  );

  const allRequiredQuestsCompleted: string[] = useMemo(
    () => getStartConditions(startNodes, "allStartConditions", "quest_completed"),
    [startNodes]
  );

  const anyRequiredQuestsCompleted: string[] = useMemo(
    () => getStartConditions(startNodes, "anyStartConditions", "quest_completed"),
    [startNodes]
  );

  const allRequiredQuestsStarted: string[] = useMemo(
    () => getStartConditions(startNodes, "allStartConditions", "quest_started"),
    [startNodes]
  );

  const anyRequiredQuestsStarted: string[] = useMemo(
    () => getStartConditions(startNodes, "anyStartConditions", "quest_started"),
    [startNodes]
  );

  const allRequiredItems: ItemNameAndQuantity[] = useMemo(
    () => getStartConditions(startNodes, "allStartConditions", "player_has_item"),
    [startNodes]
  );

  const anyRequiredItems: ItemNameAndQuantity[] = useMemo(
    () => getStartConditions(startNodes, "anyStartConditions", "player_has_item"),
    [startNodes]
  );

  const allRequiredPlayerIds: string[] = useMemo(
    () => getStartConditions(startNodes, "allStartConditions", "player_id_is"),
    [startNodes]
  );

  const anyRequiredPlayerIds: string[] = useMemo(
    () => getStartConditions(startNodes, "anyStartConditions", "player_id_is"),
    [startNodes]
  );

  const allRequiredQuestFlagsSet: string[] = useMemo(
    () => getStartConditions(startNodes, "allStartConditions", "quest_flag_is_set"),
    [startNodes]
  );

  const anyRequiredQuestFlagsSet: string[] = useMemo(
    () => getStartConditions(startNodes, "anyStartConditions", "quest_flag_is_set"),
    [startNodes]
  );

  const allRequiredQuestFlagsNotSet: string[] = useMemo(
    () => getStartConditions(startNodes, "allStartConditions", "quest_flag_is_not_set"),
    [startNodes]
  );

  const anyRequiredQuestFlagsNotSet: string[] = useMemo(
    () => getStartConditions(startNodes, "anyStartConditions", "quest_flag_is_not_set"),
    [startNodes]
  );

  const allRequiredFeatureFlagsSet: string[] = useMemo(
    () => getStartConditions(startNodes, "allStartConditions", "user_has_feature_flag"),
    [startNodes]
  );

  const anyRequiredFeatureFlagsSet: string[] = useMemo(
    () => getStartConditions(startNodes, "anyStartConditions", "user_has_feature_flag"),
    [startNodes]
  );

  const allRequiredFeatureFlagsNotSet: string[] = useMemo(
    () => getStartConditions(startNodes, "allStartConditions", "user_does_not_have_feature_flag"),
    [startNodes]
  );

  const anyRequiredFeatureFlagsNotSet: string[] = useMemo(
    () => getStartConditions(startNodes, "anyStartConditions", "user_does_not_have_feature_flag"),
    [startNodes]
  );

  const allRequiredAllegiancesAreAbove: AllegianceWithAmount[] = useMemo(
    () => getStartConditions(startNodes, "allStartConditions", "allegiance_is_above"),
    [startNodes]
  );

  const anyRequiredAllegiancesAreAbove: AllegianceWithAmount[] = useMemo(
    () => getStartConditions(startNodes, "anyStartConditions", "allegiance_is_above"),
    [startNodes]
  );

  const allRequiredAllegiancesAreBelow: AllegianceWithAmount[] = useMemo(
    () => getStartConditions(startNodes, "allStartConditions", "allegiance_is_below"),
    [startNodes]
  );

  const anyRequiredAllegiancesAreBelow: AllegianceWithAmount[] = useMemo(
    () => getStartConditions(startNodes, "anyStartConditions", "allegiance_is_below"),
    [startNodes]
  );

  const allRequiredSkillLevelsAreAboveOrEqualTo: SkillWithLevel[] = useMemo(
    () => getStartConditions(startNodes, "allStartConditions", "skill_level_is_above"),
    [startNodes]
  );

  const anyRequiredSkillLevelsAreAboveOrEqualTo: SkillWithLevel[] = useMemo(
    () => getStartConditions(startNodes, "anyStartConditions", "skill_level_is_above"),
    [startNodes]
  );

  const allRequiredSkillLevelsAreBelow: SkillWithLevel[] = useMemo(
    () => getStartConditions(startNodes, "allStartConditions", "skill_level_is_below"),
    [startNodes]
  );

  const anyRequiredSkillLevelsAreBelow: SkillWithLevel[] = useMemo(
    () => getStartConditions(startNodes, "anyStartConditions", "skill_level_is_below"),
    [startNodes]
  );

  const hasQuestStartConditions = hasStartConditions(
    allRequiredQuestsCompleted,
    anyRequiredQuestsCompleted,
    allRequiredQuestsStarted,
    anyRequiredQuestsStarted,
    allRequiredItems,
    anyRequiredItems,
    allRequiredPlayerIds,
    anyRequiredPlayerIds,
    allRequiredQuestFlagsSet,
    anyRequiredQuestFlagsSet,
    allRequiredQuestFlagsNotSet,
    anyRequiredQuestFlagsNotSet,
    allRequiredFeatureFlagsSet,
    anyRequiredFeatureFlagsSet,
    allRequiredFeatureFlagsNotSet,
    anyRequiredFeatureFlagsNotSet,
    allRequiredAllegiancesAreAbove,
    anyRequiredAllegiancesAreAbove,
    allRequiredAllegiancesAreBelow,
    anyRequiredAllegiancesAreBelow,
    allRequiredSkillLevelsAreAboveOrEqualTo,
    anyRequiredSkillLevelsAreAboveOrEqualTo,
    allRequiredSkillLevelsAreBelow,
    anyRequiredSkillLevelsAreBelow
  );

  if (!hasQuestStartConditions) {
    return null;
  }

  return (
    <Stack spacing={4}>
      <Heading size={"sm"}>
        <Text color={color} casing={"uppercase"}>
          Quest Start Conditions
        </Text>
      </Heading>

      <QuestStartConditionsByType
        color={"white"}
        label={"All Required Quests Completed"}
        items={allRequiredQuestsCompleted}
        render={(questId) => <QuestName questId={questId} />}
      />

      <QuestStartConditionsByType
        color={"white"}
        label={"Any Required Quests Completed"}
        items={anyRequiredQuestsCompleted}
        render={(questId) => <QuestName questId={questId} />}
      />

      <QuestStartConditionsByType
        color={"white"}
        label={"All Required Quests Started"}
        items={allRequiredQuestsStarted}
        render={(questId) => <QuestName questId={questId} />}
      />

      <QuestStartConditionsByType
        color={"white"}
        label={"Any Required Quests Started"}
        items={anyRequiredQuestsStarted}
        render={(questId) => <QuestName questId={questId} />}
      />

      <QuestStartConditionsByType
        color={"white"}
        label={"All Required Items"}
        items={allRequiredItems}
        render={({ itemName, quantity }) => <ItemNameAndQuantityComponent itemName={itemName} quantity={quantity} />}
      />

      <QuestStartConditionsByType
        color={"white"}
        label={"Any Required Items"}
        items={anyRequiredItems}
        render={({ itemName, quantity }) => <ItemNameAndQuantityComponent itemName={itemName} quantity={quantity} />}
      />

      <QuestStartConditionsByType
        color={"white"}
        label={"All Required Player IDs"}
        items={allRequiredPlayerIds}
        render={(playerId) => (
          <Tag p={2}>
            <Text color={"white"} casing={"uppercase"}>
              {playerId}
            </Text>
          </Tag>
        )}
      />

      <QuestStartConditionsByType
        color={"white"}
        label={"Any Required Player IDs"}
        items={anyRequiredPlayerIds}
        render={(playerId) => (
          <Tag p={2}>
            <Text color={"white"} casing={"uppercase"}>
              {playerId}
            </Text>
          </Tag>
        )}
      />

      <QuestStartConditionsByType
        color={"white"}
        label={"All Required Quest Flags Set"}
        items={allRequiredQuestFlagsSet}
        render={(questFlag) => (
          <Tag p={2}>
            <Text color={"white"} casing={"uppercase"}>
              {questFlag}
            </Text>
          </Tag>
        )}
      />

      <QuestStartConditionsByType
        color={"white"}
        label={"Any Required Quest Flags Set"}
        items={anyRequiredQuestFlagsSet}
        render={(questFlag) => (
          <Tag p={2}>
            <Text color={"white"} casing={"uppercase"}>
              {questFlag}
            </Text>
          </Tag>
        )}
      />

      <QuestStartConditionsByType
        color={"white"}
        label={"All Required Quest Flags Not Set"}
        items={allRequiredQuestFlagsNotSet}
        render={(questFlag) => (
          <Tag p={2}>
            <Text color={"white"} casing={"uppercase"}>
              {questFlag}
            </Text>
          </Tag>
        )}
      />

      <QuestStartConditionsByType
        color={"white"}
        label={"Any Required Quest Flags Not Set"}
        items={anyRequiredQuestFlagsNotSet}
        render={(questFlag) => (
          <Tag p={2}>
            <Text color={"white"} casing={"uppercase"}>
              {questFlag}
            </Text>
          </Tag>
        )}
      />

      <QuestStartConditionsByType
        color={"white"}
        label={"All Required Feature Flags Set"}
        items={allRequiredFeatureFlagsSet}
        render={(featureFlag) => (
          <Tag p={2}>
            <Text color={"white"} casing={"uppercase"}>
              {featureFlag}
            </Text>
          </Tag>
        )}
      />

      <QuestStartConditionsByType
        color={"white"}
        label={"Any Required Feature Flags Set"}
        items={anyRequiredFeatureFlagsSet}
        render={(featureFlag) => (
          <Tag p={2}>
            <Text color={"white"} casing={"uppercase"}>
              {featureFlag}
            </Text>
          </Tag>
        )}
      />

      <QuestStartConditionsByType
        color={"white"}
        label={"All Required Feature Flags Not Set"}
        items={allRequiredFeatureFlagsNotSet}
        render={(featureFlag) => (
          <Tag p={2}>
            <Text color={"white"} casing={"uppercase"}>
              {featureFlag}
            </Text>
          </Tag>
        )}
      />

      <QuestStartConditionsByType
        color={"white"}
        label={"Any Required Feature Flags Not Set"}
        items={anyRequiredFeatureFlagsNotSet}
        render={(featureFlag) => (
          <Tag p={2}>
            <Text color={"white"} casing={"uppercase"}>
              {featureFlag}
            </Text>
          </Tag>
        )}
      />

      <QuestStartConditionsByType
        color={"white"}
        label={"All Required Allegiances Are Above"}
        items={allRequiredAllegiancesAreAbove}
        render={({ allegianceId, allegianceAmount }) => (
          <AllegianceWithAmountComponent allegianceId={allegianceId} allegianceAmount={allegianceAmount} />
        )}
      />

      <QuestStartConditionsByType
        color={"white"}
        label={"Any Required Allegiances Are Above"}
        items={anyRequiredAllegiancesAreAbove}
        render={({ allegianceId, allegianceAmount }) => (
          <AllegianceWithAmountComponent allegianceId={allegianceId} allegianceAmount={allegianceAmount} />
        )}
      />

      <QuestStartConditionsByType
        color={"white"}
        label={"All Required Allegiances Are Below"}
        items={allRequiredAllegiancesAreBelow}
        render={({ allegianceId, allegianceAmount }) => (
          <AllegianceWithAmountComponent allegianceId={allegianceId} allegianceAmount={allegianceAmount} />
        )}
      />

      <QuestStartConditionsByType
        color={"white"}
        label={"Any Required Allegiances Are Below"}
        items={anyRequiredAllegiancesAreBelow}
        render={({ allegianceId, allegianceAmount }) => (
          <AllegianceWithAmountComponent allegianceId={allegianceId} allegianceAmount={allegianceAmount} />
        )}
      />

      <QuestStartConditionsByType
        color={"white"}
        label={"All Required Skill Levels Are Above Or Equal To"}
        items={allRequiredSkillLevelsAreAboveOrEqualTo}
        render={({ skillId, level }) => <SkillWithLevelComponent skillId={skillId} level={level} />}
      />

      <QuestStartConditionsByType
        color={"white"}
        label={"Any Required Skill Levels Are Above Or Equal To"}
        items={anyRequiredSkillLevelsAreAboveOrEqualTo}
        render={({ skillId, level }) => <SkillWithLevelComponent skillId={skillId} level={level} />}
      />

      <QuestStartConditionsByType
        color={"white"}
        label={"All Required Skill Levels Are Below"}
        items={allRequiredSkillLevelsAreBelow}
        render={({ skillId, level }) => <SkillWithLevelComponent skillId={skillId} level={level} />}
      />

      <QuestStartConditionsByType
        color={"white"}
        label={"Any Required Skill Levels Are Below"}
        items={anyRequiredSkillLevelsAreBelow}
        render={({ skillId, level }) => <SkillWithLevelComponent skillId={skillId} level={level} />}
      />
    </Stack>
  );
}

function getStartConditions<T>(
  startNodes: Node<NodeType<StartNodeData>>[],
  startConditionKind: keyof Pick<StartNodeData, "allStartConditions" | "anyStartConditions">,
  startConditionType: StartConditionType
): T[] {
  return startNodes.flatMap(({ data: { nodeData } }) =>
    nodeData[startConditionKind].filter(({ type }) => type === startConditionType).map(({ value }) => value)
  );
}

function hasStartConditions(...startConditions: unknown[][]) {
  return startConditions.flatMap((startConditions) => startConditions).length !== 0;
}

export default memo(QuestStartConditions);
