import { Button, ButtonProps } from "@chakra-ui/react";
import { useMemo } from "react";
import useUpdateQuestMetaData from "../../hooks/quests/useUpdateQuestMetaData";

interface QuestReadyToggleProps extends ButtonProps {
  questId: string;
  isReady: boolean;
}

function QuestReadyToggle({ questId, isReady, ...buttonProps }: QuestReadyToggleProps) {
  const { isUpdating, updateQuestMetaData } = useUpdateQuestMetaData();

  function handleToggleReady() {
    updateQuestMetaData({
      questId,
      partialQuest: {
        isReady: !isReady,
      },
    });
  }

  const isReadyColor = isReady ? "green.600" : "red.800";

  const _hoverProps: ButtonProps = useMemo(
    () => ({
      _hover: {
        color: isReadyColor,
        bg: "black",
        borderColor: isReadyColor,
      },
      color: "black",
      bg: isReadyColor,
      borderColor: "black",
    }),
    [isReady]
  );

  return (
    <Button
      onClick={handleToggleReady}
      textTransform={"uppercase"}
      borderRadius={0}
      borderWidth={2}
      {..._hoverProps}
      {...buttonProps}
      isLoading={isUpdating || buttonProps.isLoading}
    >
      {isReady ? "ready" : "not ready"}
    </Button>
  );
}

export default QuestReadyToggle;
