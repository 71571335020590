import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { StoreDispatch, StoreState } from "./types";
import { Edge, Node } from "reactflow";
import { NodeData, NodeHandle, NodeType, SourceHandle, TargetHandle } from "../models/nodeType";
import { EdgeType } from "../models/edgeType";
import { QuestData, QuestWithId } from "../models/api/quest";
import { getQuest } from "../api/quests/quests";
import { ulid } from "ulid";

// TODO: cleanup start

export type StartConditionType =
  | "quest_completed"
  | "quest_started"
  | "player_has_item"
  | "player_id_is"
  | "quest_flag_is_set"
  | "quest_flag_is_not_set"
  | "user_has_feature_flag"
  | "user_does_not_have_feature_flag"
  | "allegiance_is_above"
  | "allegiance_is_below"
  | "skill_level_is_above"
  | "skill_level_is_below";

export interface StartCondition<T> {
  type: StartConditionType;
  value: T;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface StartConditionWithId<T = any> extends StartCondition<T> {
  id: string;
}

export interface StartNodeData extends NodeData {
  allStartConditions: StartConditionWithId[];
  anyStartConditions: StartConditionWithId[];
}

// TODO: cleanup end

export type QuestNode = Node<NodeType<QuestData>>;

export type QuestEdge = Edge<EdgeType>;

export type Status = "idle" | "loading" | "success" | "error";

export type QuestNotificationMessageType = "info" | "warning" | "success" | "error";

export interface QuestNotification {
  questId: string;
  questDisplayName: string;
  message: QuestNotificationMessage;
}

export interface QuestNotificationMessage {
  type: QuestNotificationMessageType;
  text: string;
  time: string;
}

export interface Quests {
  questNodes: QuestNode[];
  questEdges: QuestEdge[];
  status: Status;
  notifications: QuestNotification[];
  notificationsUnreadCounter: number;
}

const initialState: Quests = {
  questNodes: [],
  questEdges: [],
  status: "idle",
  notifications: [],
  notificationsUnreadCounter: 0,
};

const slice = createSlice({
  name: "quests",
  initialState,
  reducers: {
    setQuests(
      state,
      { payload: { questNodes, questEdges } }: PayloadAction<Pick<Quests, "questNodes" | "questEdges">>
    ) {
      state.questNodes = questNodes;
      state.questEdges = questEdges;
    },
    insertQuestNotifications(state, { payload: questNotifications }: PayloadAction<QuestNotification[]>) {
      state.notifications = state.notifications.concat(questNotifications);
      state.notificationsUnreadCounter += questNotifications.length;
    },
    removeQuestNotification(state, { payload: questId }: PayloadAction<string>) {
      state.notifications = state.notifications.filter((notification) => notification.questId !== questId);
    },
    removeQuestNotifications(state) {
      state.notifications = [];
    },
  },
  extraReducers(builder) {
    builder
      .addCase(updateQuestNodes.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateQuestNodes.fulfilled, (state) => {
        state.status = "idle";
      })
      .addCase(updateQuestNodes.rejected, (state) => {
        state.status = "error";
      });
  },
});

export const setQuests = slice.actions.setQuests;
export const insertQuestNotifications = slice.actions.insertQuestNotifications;
export const removeQuestNotification = slice.actions.removeQuestNotification;
export const removeQuestNotifications = slice.actions.removeQuestNotifications;

export const updateQuestNodes = createAsyncThunk<void, QuestWithId[], { dispatch: StoreDispatch; state: StoreState }>(
  "quests/updateQuestNodes",
  async function (quests, { dispatch, getState }) {
    const {
      quests: { questNodes, questEdges },
    } = getState();

    const { updatedQuestNodes, updatedQuestEdges, questNotifications } = await getUpdatedQuestNodesAndEdges(
      questNodes,
      questEdges,
      quests
    );

    dispatch(setQuests({ questNodes: updatedQuestNodes, questEdges: updatedQuestEdges }));
    dispatch(insertQuestNotifications(questNotifications));
  }
);

async function getUpdatedQuestNodesAndEdges(questNodes: QuestNode[], questEdges: QuestEdge[], quests: QuestWithId[]) {
  const { cleanQuestNodes, dirtyQuestNodes, questNotifications } = getUpdatedQuestNodes(questNodes, quests);

  let updatedQuestNodes = [...cleanQuestNodes, ...dirtyQuestNodes];
  let updatedQuestEdges = questEdges.slice();

  let updatedQuests: QuestWithId[] = [];

  try {
    updatedQuests = await Promise.all(dirtyQuestNodes.map(({ id }) => getQuest(id)));
  } catch (error) {
    console.error(error);
  }

  for (const updatedQuest of updatedQuests) {
    const { questNodes, questEdges } = getUpdatedQuestNodesAndEdgesByQuestId(
      updatedQuestNodes,
      updatedQuestEdges,
      updatedQuest
    );

    updatedQuestNodes = questNodes;
    updatedQuestEdges = questEdges;
  }

  return { updatedQuestNodes, updatedQuestEdges, questNotifications };
}

function getUpdatedQuestNodesAndEdgesByQuestId(questNodes: QuestNode[], questEdges: QuestEdge[], quest: QuestWithId) {
  const { questId, data: questNodeData } = quest;

  if (questNodeData == null) {
    return { questNodes, questEdges };
  }

  const questNode = questNodes.find((questNode) => questNode.id === questId);

  if (questNode == null) {
    return { questNodes, questEdges };
  }

  const { missingDependingSourceHandles, updatedDependingTargetHandles } = getDependingHandles(
    questNodes,
    questId,
    getDependingQuestStartConditions(quest)
  );

  const { updatedDependentSourceHandles, missingDependentTargetHandles } = getDependentHandles(
    questNodes,
    questId,
    getDependentQuestStartConditions(quest, questNodes)
  );

  let updatedQuestNodes = updateQuestNode(questNodes, questId, {
    sourceHandles: updatedDependentSourceHandles,
    targetHandles: updatedDependingTargetHandles,
    nodeData: { ...questNodeData },
  });

  updatedQuestNodes = updateNeighbouringQuestNodes(
    updatedQuestNodes,
    missingDependingSourceHandles,
    missingDependentTargetHandles
  );

  let updatedQuestEdges = questEdges.slice();

  updatedQuestEdges = updateNeighbouringQuestEdges(
    updatedQuestEdges,
    updatedDependingTargetHandles,
    updatedDependentSourceHandles,
    questId
  );

  return {
    questNodes: updatedQuestNodes,
    questEdges: updatedQuestEdges,
  };
}

function updateQuestNode(questNodes: QuestNode[], questNodeId: string, questData: Partial<NodeType<QuestData>>) {
  return questNodes.map((questNode) => {
    if (questNode.id !== questNodeId) {
      return questNode;
    }

    return {
      ...questNode,
      data: {
        ...questNode.data,
        ...questData,
      },
    };
  });
}

function updateQuestNodeSourceHandles(questNodes: QuestNode[], questNodeId: string, sourceHandles: SourceHandle[]) {
  return questNodes.map((questNode) => {
    if (questNode.id !== questNodeId) {
      return questNode;
    }

    return {
      ...questNode,
      data: {
        ...questNode.data,
        sourceHandles,
      },
    };
  });
}

function updateQuestNodeTargetHandles(questNodes: QuestNode[], questNodeId: string, targetHandles: TargetHandle[]) {
  return questNodes.map((questNode) => {
    if (questNode.id !== questNodeId) {
      return questNode;
    }

    return {
      ...questNode,
      data: {
        ...questNode.data,
        targetHandles,
      },
    };
  });
}

function createNeighbouringQuestNodeSourceHandles(questNodes: QuestNode[], missingSourceHandles: SourceHandle[]) {
  const groupedMissingSourceHandles = groupNodeHandlesByNodeId(missingSourceHandles);

  let updatedQuestNodes = questNodes.slice();

  Object.entries(groupedMissingSourceHandles).forEach(([nodeId, missingSourceHandles]) => {
    const node = updatedQuestNodes.find(({ id }) => id === nodeId);

    if (node == null) {
      return;
    }

    const currentSourceHandles = node.data.sourceHandles ?? [];

    updatedQuestNodes = updateQuestNodeSourceHandles(updatedQuestNodes, nodeId, [
      ...currentSourceHandles,
      ...missingSourceHandles,
    ]);
  });

  return updatedQuestNodes;
}

function createNeighbouringQuestNodeTargetHandles(questNodes: QuestNode[], missingTargetHandles: TargetHandle[]) {
  const groupedMissingTargetHandles = groupNodeHandlesByNodeId(missingTargetHandles);

  let updatedQuestNodes = questNodes.slice();

  Object.entries(groupedMissingTargetHandles).forEach(([nodeId, missingTargetHandles]) => {
    const node = updatedQuestNodes.find(({ id }) => id === nodeId);

    if (node == null) {
      return;
    }

    const currentTargetHandles = node.data.targetHandles ?? [];

    updatedQuestNodes = updateQuestNodeTargetHandles(updatedQuestNodes, nodeId, [
      ...currentTargetHandles,
      ...missingTargetHandles,
    ]);
  });

  return updatedQuestNodes;
}

function updateNeighbouringQuestNodes(
  questNodes: QuestNode[],
  missingSourceHandles: SourceHandle[],
  missingTargetHandles: TargetHandle[]
) {
  let updatedQuestNodes = questNodes.slice();

  updatedQuestNodes = createNeighbouringQuestNodeSourceHandles(updatedQuestNodes, missingSourceHandles);
  updatedQuestNodes = createNeighbouringQuestNodeTargetHandles(updatedQuestNodes, missingTargetHandles);

  return updatedQuestNodes;
}

function updateNeighbouringQuestEdges(
  questEdges: QuestEdge[],
  dependingTargetHandles: TargetHandle[],
  dependentSourceHandles: SourceHandle[],
  questId: string
) {
  return [
    ...questEdges.slice(),
    ...getMissingDependingEdges(questEdges, questId, dependingTargetHandles),
    ...getMissingDependentEdges(questEdges, questId, dependentSourceHandles),
  ];
}

function getMissingDependingSourceHandle(
  nodes: Node<NodeType>[],
  sourceQuestNodeId: string,
  targetQuestNodeId: string,
  targetStartConditionId: string,
  handleName: string,
  handleLabel: string
) {
  const sourceNode = nodes.find(({ id }) => id === sourceQuestNodeId);

  if (sourceNode == null) {
    return null;
  }

  const sourceHandle = sourceNode.data.sourceHandles?.find(
    (sourceHandle) => sourceHandle.handleId === targetStartConditionId
  );

  if (sourceHandle != null) {
    return null;
  }

  return createSourceQuestNodeHandle(sourceQuestNodeId, targetStartConditionId, handleName, handleLabel);
}

function getMissingDependingSourceHandles(
  nodes: Node<NodeType>[],
  targetQuestNodeId: string,
  {
    allQuestsCompleted,
    anyQuestsCompleted,
    allQuestsStarted,
    anyQuestsStarted,
  }: ReturnType<typeof getDependingQuestStartConditions>
) {
  return [
    ...allQuestsCompleted.map(([sourceQuestNodeId, targetStartConditionId]) =>
      getMissingDependingSourceHandle(
        nodes,
        sourceQuestNodeId,
        targetQuestNodeId,
        targetStartConditionId,
        "all_quests_completed",
        "All Quests Completed"
      )
    ),
    ...anyQuestsCompleted.map(([sourceQuestNodeId, targetStartConditionId]) =>
      getMissingDependingSourceHandle(
        nodes,
        sourceQuestNodeId,
        targetQuestNodeId,
        targetStartConditionId,
        "any_quests_completed",
        "Any Quests Completed"
      )
    ),
    ...allQuestsStarted.map(([sourceQuestNodeId, targetStartConditionId]) =>
      getMissingDependingSourceHandle(
        nodes,
        sourceQuestNodeId,
        targetQuestNodeId,
        targetStartConditionId,
        "all_quests_started",
        "All Quests Started"
      )
    ),
    ...anyQuestsStarted.map(([sourceQuestNodeId, targetStartConditionId]) =>
      getMissingDependingSourceHandle(
        nodes,
        sourceQuestNodeId,
        targetQuestNodeId,
        targetStartConditionId,
        "any_quests_started",
        "Any Quests Started"
      )
    ),
  ];
}

function getUpdatedDependingTargetHandles(
  targetQuestNodeId: string,
  {
    allQuestsCompleted,
    anyQuestsCompleted,
    allQuestsStarted,
    anyQuestsStarted,
  }: ReturnType<typeof getDependingQuestStartConditions>
) {
  return [
    ...allQuestsCompleted.map(([sourceQuestNodeId, targetStartConditionId]) =>
      createTargetQuestNodeHandle(
        sourceQuestNodeId,
        targetStartConditionId,
        "all_quests_completed",
        "All Quests Completed"
      )
    ),
    ...anyQuestsCompleted.map(([sourceQuestNodeId, targetStartConditionId]) =>
      createTargetQuestNodeHandle(
        sourceQuestNodeId,
        targetStartConditionId,
        "any_quests_completed",
        "Any Quests Completed"
      )
    ),
    ...allQuestsStarted.map(([sourceQuestNodeId, targetStartConditionId]) =>
      createTargetQuestNodeHandle(sourceQuestNodeId, targetStartConditionId, "all_quests_started", "All Quests Started")
    ),
    ...anyQuestsStarted.map(([sourceQuestNodeId, targetStartConditionId]) =>
      createTargetQuestNodeHandle(sourceQuestNodeId, targetStartConditionId, "any_quests_started", "Any Quests Started")
    ),
  ];
}

function getUpdatedDependentSourceHandles(
  sourceQuestNodeId: string,
  {
    allQuestsCompleted,
    anyQuestsCompleted,
    allQuestsStarted,
    anyQuestsStarted,
  }: ReturnType<typeof getDependentQuestStartConditions>
) {
  return [
    ...allQuestsCompleted.map(([targetQuestNodeId, targetStartConditionId]) =>
      createSourceQuestNodeHandle(
        targetQuestNodeId,
        targetStartConditionId,
        "all_quests_completed",
        "All Quests Completed"
      )
    ),
    ...anyQuestsCompleted.map(([targetQuestNodeId, targetStartConditionId]) =>
      createSourceQuestNodeHandle(
        targetQuestNodeId,
        targetStartConditionId,
        "any_quests_completed",
        "Any Quests Completed"
      )
    ),
    ...allQuestsStarted.map(([targetQuestNodeId, targetStartConditionId]) =>
      createSourceQuestNodeHandle(targetQuestNodeId, targetStartConditionId, "all_quests_started", "All Quests Started")
    ),
    ...anyQuestsStarted.map(([targetQuestNodeId, targetStartConditionId]) =>
      createSourceQuestNodeHandle(targetQuestNodeId, targetStartConditionId, "any_quests_started", "Any Quests Started")
    ),
  ];
}

function getMissingDependentTargetHandle(
  nodes: Node<NodeType>[],
  sourceQuestNodeId: string,
  targetQuestNodeId: string,
  targetStartConditionId: string,
  handleName: string,
  handleLabel: string
) {
  const targetNode = nodes.find(({ id }) => id === targetQuestNodeId);

  if (targetNode == null) {
    return null;
  }

  const targetHandle = targetNode.data.targetHandles?.find(
    (targetHandle) => targetHandle.handleId === targetStartConditionId
  );

  if (targetHandle != null) {
    return null;
  }

  return createTargetQuestNodeHandle(targetQuestNodeId, targetStartConditionId, handleName, handleLabel);
}

function getMissingDependentTargetHandles(
  nodes: Node<NodeType>[],
  sourceQuestNodeId: string,
  {
    allQuestsCompleted,
    anyQuestsCompleted,
    allQuestsStarted,
    anyQuestsStarted,
  }: ReturnType<typeof getDependentQuestStartConditions>
) {
  return [
    ...allQuestsCompleted.map(([targetQuestNodeId, targetStartConditionId]) =>
      getMissingDependentTargetHandle(
        nodes,
        sourceQuestNodeId,
        targetQuestNodeId,
        targetStartConditionId,
        "all_quests_completed",
        "All Quests Completed"
      )
    ),
    ...anyQuestsCompleted.map(([targetQuestNodeId, targetStartConditionId]) =>
      getMissingDependentTargetHandle(
        nodes,
        sourceQuestNodeId,
        targetQuestNodeId,
        targetStartConditionId,
        "any_quests_completed",
        "Any Quests Completed"
      )
    ),
    ...allQuestsStarted.map(([targetQuestNodeId, targetStartConditionId]) =>
      getMissingDependentTargetHandle(
        nodes,
        sourceQuestNodeId,
        targetQuestNodeId,
        targetStartConditionId,
        "all_quests_started",
        "All Quests Started"
      )
    ),
    ...anyQuestsStarted.map(([targetQuestNodeId, targetStartConditionId]) =>
      getMissingDependentTargetHandle(
        nodes,
        sourceQuestNodeId,
        targetQuestNodeId,
        targetStartConditionId,
        "any_quests_started",
        "Any Quests Started"
      )
    ),
  ];
}

function getDependingHandles(
  nodes: Node<NodeType>[],
  targetQuestNodeId: string,
  dependingQuestStartConditions: ReturnType<typeof getDependingQuestStartConditions>
) {
  const missingDependingSourceHandles = getMissingDependingSourceHandles(
    nodes,
    targetQuestNodeId,
    dependingQuestStartConditions
  );

  const updatedDependingTargetHandles = getUpdatedDependingTargetHandles(
    targetQuestNodeId,
    dependingQuestStartConditions
  );

  return {
    missingDependingSourceHandles: missingDependingSourceHandles.filter(
      (sourceHandle) => sourceHandle != null
    ) as SourceHandle[],
    updatedDependingTargetHandles,
  };
}

function getDependentHandles(
  nodes: Node<NodeType>[],
  sourceQuestNodeId: string,
  dependentQuestStartConditions: ReturnType<typeof getDependentQuestStartConditions>
) {
  const updatedDependentSourceHandles = getUpdatedDependentSourceHandles(
    sourceQuestNodeId,
    dependentQuestStartConditions
  );

  const missingDependentTargetHandles = getMissingDependentTargetHandles(
    nodes,
    sourceQuestNodeId,
    dependentQuestStartConditions
  );

  return {
    updatedDependentSourceHandles,
    missingDependentTargetHandles: missingDependentTargetHandles.filter(
      (targetHandle) => targetHandle != null
    ) as TargetHandle[],
  };
}

function groupNodeHandlesByNodeId<T extends NodeHandle>(nodeHandles: T[]) {
  return nodeHandles.reduce((groupedNodeHandles: Record<string, T[]>, nodeHandle: T) => {
    const { linkedNodeDataId } = nodeHandle;

    if (linkedNodeDataId == null) {
      return groupedNodeHandles;
    }

    return {
      ...groupedNodeHandles,
      [linkedNodeDataId]: [...(groupedNodeHandles[linkedNodeDataId] || []), nodeHandle],
    };
  }, {});
}

function getMissingDependingEdges(edges: Edge<EdgeType>[], target: string, dependingTargetHandles: TargetHandle[]) {
  const missingEdges: Edge<EdgeType>[] = [];

  dependingTargetHandles.forEach(({ linkedNodeDataId: source, handleId }) => {
    if (source == null) {
      return;
    }

    if (handleId == null) {
      return;
    }

    if (edgeExists(edges, source, handleId, target, handleId)) {
      return;
    }

    missingEdges.push(createQuestEdge(source, handleId, target, handleId));
  });

  return missingEdges;
}

function getMissingDependentEdges(edges: Edge<EdgeType>[], source: string, dependentSourceHandles: SourceHandle[]) {
  const missingEdges: Edge<EdgeType>[] = [];

  dependentSourceHandles.forEach(({ linkedNodeDataId: target, handleId }) => {
    if (target == null) {
      return;
    }

    if (handleId == null) {
      return;
    }

    if (edgeExists(edges, source, handleId, target, handleId)) {
      return;
    }

    missingEdges.push(createQuestEdge(source, handleId, target, handleId));
  });

  return missingEdges;
}

function edgeExists(
  edges: Edge<EdgeType>[],
  source?: string,
  sourceHandle?: string,
  target?: string,
  targetHandle?: string
) {
  return edges.some(
    (edge) =>
      edge.source === source &&
      edge.sourceHandle === sourceHandle &&
      edge.target === target &&
      edge.targetHandle === targetHandle
  );
}

const currentQuestNodeSelector = (quests: QuestWithId[], { id, data: { version } }: QuestNode) =>
  quests.some(({ questId, version: questVersion }) => questId === id && questVersion === version);

const updatedQuestSelector = (questNodes: QuestNode[], { questId, version: questVersion }: QuestWithId) =>
  questNodes.some(({ id, data: { version } }) => id === questId && version !== questVersion);

const createdQuestSelector = (questNodes: QuestNode[], { questId }: QuestWithId) =>
  !questNodes.some(({ id }) => id === questId);

function getUpdatedQuestNodes(questNodes: QuestNode[], quests: QuestWithId[]) {
  const currentQuestNodes = questNodes.filter((currentQuestNode) => currentQuestNodeSelector(quests, currentQuestNode));
  const updatedQuestNodes = quests.filter((quest) => updatedQuestSelector(questNodes, quest)).map(createQuestNode);
  const createdQuestNodes = quests.filter((quest) => createdQuestSelector(questNodes, quest)).map(createQuestNode);

  console.info("generating quest nodes...");
  console.info("current quests", currentQuestNodes);
  console.info("updated quests", updatedQuestNodes);
  console.info("created quests", createdQuestNodes);

  const questNotifications: QuestNotification[] = updatedQuestNodes.map(
    ({ id: questId, data: { label: questDisplayName = "" } }) => ({
      questId,
      questDisplayName,
      message: {
        type: "info",
        text: "quest has been updated",
        time: new Date().toUTCString(),
      },
    })
  );

  return {
    cleanQuestNodes: currentQuestNodes,
    dirtyQuestNodes: [...updatedQuestNodes, ...createdQuestNodes],
    questNotifications,
  };
}

function createQuestNode({
  questId: id,
  version,
  isReady,
  name: label,
  description: nodeDescription,
  questEditorPosition,
}: QuestWithId): QuestNode {
  return {
    id,
    type: "QuestNode",
    data: {
      label,
      color: "red.800",
      nodeName: "quest",
      nodeData: {
        edges: [],
        nodes: [],
      },
      nodeClass: "quest",
      nodeDescription,
      sourceHandles: [],
      ignoreSourceHandlesDiff: true,
      isSourceHandlesEditable: false,
      targetHandles: [],
      ignoreTargetHandlesDiff: true,
      isTargetHandlesEditable: false,
      version,
      isReady,
      isEditable: false,
    },
    position: questEditorPosition ?? { x: 0, y: 0 },
  };
}

function createSourceQuestNodeHandle(
  linkedQuestNodeId: string,
  handleId: string,
  handleName: string,
  label: string
): SourceHandle {
  return {
    label,
    handleId,
    handleName,
    handleType: "source",
    handleCategory: "quest",
    linkedNodeDataId: linkedQuestNodeId,
  };
}

function createTargetQuestNodeHandle(
  linkedQuestNodeId: string,
  handleId: string,
  handleName: string,
  label: string
): TargetHandle {
  return {
    label,
    handleId,
    handleName,
    handleType: "target",
    handleCategory: "quest",
    linkedNodeDataId: linkedQuestNodeId,
  };
}

function createQuestEdge(source: string, sourceHandle: string, target: string, targetHandle: string): QuestEdge {
  return {
    id: ulid(),
    type: "QuestEdge",
    source,
    sourceHandle,
    target,
    targetHandle,
  };
}

function getDependingQuestStartConditions(quest: QuestWithId) {
  const { questId, data: { nodes } = { nodes: [] } } = quest;

  const allQuestsCompleted = getDependingQuestStartConditionEntries(
    nodes,
    "allStartConditions",
    "quest_completed",
    questId
  );

  const anyQuestsCompleted = getDependingQuestStartConditionEntries(
    nodes,
    "anyStartConditions",
    "quest_completed",
    questId
  );

  const allQuestsStarted = getDependingQuestStartConditionEntries(
    nodes,
    "allStartConditions",
    "quest_started",
    questId
  );

  const anyQuestsStarted = getDependingQuestStartConditionEntries(
    nodes,
    "anyStartConditions",
    "quest_started",
    questId
  );

  return {
    allQuestsCompleted,
    anyQuestsCompleted,
    allQuestsStarted,
    anyQuestsStarted,
  };
}

function getDependingQuestStartConditionEntries(
  nodes: Node<NodeType>[],
  startConditionKind: keyof Pick<StartNodeData, "allStartConditions" | "anyStartConditions">,
  questConditionType: Extract<StartConditionType, "quest_completed" | "quest_started">,
  questId: string
): string[][] {
  return nodes
    .filter(({ data: { nodeClass } }) => nodeClass === "start")
    .flatMap(({ data: { nodeData } }: Node<NodeType<StartNodeData>>) =>
      nodeData[startConditionKind]
        .filter(({ type }) => type === questConditionType)
        .map(({ id: startConditionId, value: questNodeId }) => [questNodeId, startConditionId])
    );
}

function getDependentQuestStartConditions(quest: QuestWithId, questNodes: QuestNode[]) {
  const { questId } = quest;

  const allQuestsCompleted = getDependentQuestStartConditionEntries(
    questNodes,
    "allStartConditions",
    "quest_completed",
    questId
  );

  const anyQuestsCompleted = getDependentQuestStartConditionEntries(
    questNodes,
    "anyStartConditions",
    "quest_completed",
    questId
  );

  const allQuestsStarted = getDependentQuestStartConditionEntries(
    questNodes,
    "allStartConditions",
    "quest_started",
    questId
  );

  const anyQuestsStarted = getDependentQuestStartConditionEntries(
    questNodes,
    "anyStartConditions",
    "quest_started",
    questId
  );

  return {
    allQuestsCompleted,
    anyQuestsCompleted,
    allQuestsStarted,
    anyQuestsStarted,
  };
}

function getDependentQuestStartConditionEntries(
  questNodes: QuestNode[],
  startConditionKind: keyof Pick<StartNodeData, "allStartConditions" | "anyStartConditions">,
  questConditionType: Extract<StartConditionType, "quest_completed" | "quest_started">,
  questId: string
): string[][] {
  return questNodes.flatMap(
    ({
      id: questNodeId,
      data: {
        nodeData: { nodes },
      },
    }) =>
      nodes
        .filter(({ data: { nodeClass } }) => nodeClass === "start")
        .flatMap(({ data: { nodeData } }: Node<NodeType<StartNodeData>>) =>
          nodeData[startConditionKind]
            .filter(({ type, value }) => type === questConditionType && value === questId)
            .map(({ id: startConditionId }) => [questNodeId, startConditionId])
        )
  );
}

export default slice.reducer;
